<template>
  <div>
    <div style="margin: 10px 0">
      <span class="badge-label">工牌名称</span>
          <el-input style="width: 200px" placeholder="请输入工牌名称" suffix-icon="el-icon-search" class="ml-5" v-model="tagID"></el-input>
      <el-button class="ml-5" type="primary" icon="el-icon-search" @click="load">查询</el-button>
      <el-button type="danger" icon="el-icon-warning-outline" @click="reset">重置</el-button>
    </div>
    <div style="margin: 10px 0">
       <el-button type="danger" @click="delBatch">批量删除 <i class="el-icon-delete"></i></el-button>
      <el-button type="primary" @click="exp" class="ml-5">导出数据 <i class="el-icon-download"></i></el-button>
    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'TableHeader'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="序号" width="80" sortable></el-table-column>
      <el-table-column prop="tagID" label="工牌名称"></el-table-column>
      <el-table-column prop="x" label="当前x位置"></el-table-column>
      <el-table-column prop="y" label="当前y位置"></el-table-column>
      <el-table-column prop="time" label="报警时间" :formatter="formatTime"></el-table-column>
    </el-table>
    <div style="padding: 10px 500px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import webSocketService from "@/services/websocketService";

export default {
  name: "Uwb",
  data() {
    return {
      tagID: "",
      // 定义电子围栏范围
      fenceXMin: 0,
      fenceXMax: 1.9,
      fenceYMin: 0,
      fenceYMax: 5.2,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  computed: {
    tableData() {
      return this.$store.state.uwbData;
    },
    total() {
      return this.$store.state.total;
    },
    pageNum() {
      return this.$store.state.pageNum;
    },
    pageSize() {
      return this.$store.state.pageSize;
    },
  },
  watch: {
    tagID(newVal) {
      this.load();
    },
    pageNum(newVal) {
      this.load();
    },
    pageSize(newVal) {
      this.load();
    },
  },
  created() {
    this.load();
    this.$bus.$on('websocketMessage', this.handleMessage);
  },
  /*beforeDestroy() {
    this.$bus.$off('websocketMessage', this.handleMessage);
  },*/
  methods: {
    reset() {
      this.tagID = "";
      this.load();
    },
    handleMessage(event) {
      const jsonData = JSON.parse(event.data);
      if (jsonData.command === 'UpLink') {
        const { tagID, x, y } = jsonData;

        // 更新表格中的位置信息
        this.$store.commit('updateUwbPosition', { tagID, x, y });

        // 检查是否超出电子围栏
        if (!this.isWithinFence(x, y)) {
          this.recordViolation(tagID, x, y); // 记录超出围栏信息
        }
      }
    },
    isWithinFence(x, y) {
      // 检查位置是否在电子围栏范围内
      return (
          x >= this.fenceXMin &&
          x <= this.fenceXMax &&
          y >= this.fenceYMin &&
          y <= this.fenceYMax
      );
    },

    recordViolation(tagID, x, y) {
      // 记录超出围栏信息并保存到数据库
      const violation = {
        tagID,
        x,
        y,
        time: new Date().toISOString(), // 获取当前时间
      };

      this.request.post('/uwb/violation', violation).then(res => {
        if (res.code === '200') {
          console.log('超出围栏信息已保存');
        } else {
          console.error('保存超出围栏信息失败');
        }
      }).catch(error => {
        console.error('保存超出围栏信息时发生错误:', error);
      });
    },
    formatTime(row, column, cellValue) {
      if (!cellValue) return ''; // 如果时间为空，则返回空字符串
      const date = new Date(cellValue);
      if (isNaN(date.getTime())) return cellValue; // 如果时间无效，则返回原始值

      // 转换为东八区时间
      const localTime = new Date(date.getTime() + (0 * 60 * 60 * 1000));

      // 格式化为 YYYY-MM-DD HH:mm:ss
      const year = localTime.getFullYear();
      const month = String(localTime.getMonth() + 1).padStart(2, '0');
      const day = String(localTime.getDate()).padStart(2, '0');
      const hours = String(localTime.getHours()).padStart(2, '0');
      const minutes = String(localTime.getMinutes()).padStart(2, '0');
      const seconds = String(localTime.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    load() {
      this.$store.dispatch('loadUwbData', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tagID: this.tagID,
      });
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      // 首先检查是否有多选的数据
      if (this.multipleSelection.length === 0) {
        this.$message.error("请至少选择一条数据！");
        // 如果没有选择任何数据，则直接返回，不执行后续操作
        return;
      }
      // 使用Promise链确保先弹出确认框，再根据用户选择执行删除操作
      this.$confirm('您确定批量删除选中的数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户确认删除后，提取选中项ID并发起删除请求
        let ids = this.multipleSelection.map(v => v.id);
        // 发起POST请求删除选中项
        return this.request.post("/uwb/del/batch", ids).then(res => {
          if (res.code === '200') {
            this.$message.success("批量删除成功");
            // 删除成功后刷新数据
            this.load();
          }
        })
      })
    },

    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.$store.commit('setPageSize', pageSize);
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.$store.commit('setPageNum', pageNum);
      this.load()
    },

    exp() {
      window.open("https://lkwyznkj.cyou:9090/uwb/export")
      this.$message.success("导出成功")
      this.load()
    },

  }
}
</script>

<style>
.TableHeader {
  background: #eee!important;
}
</style>
